.Root {
  margin: 18px 0 25px 70px;
}

.RadioItem {
  display: flex;
  /* margin-left: 73px; */
  /* margin-top: 40px; */
  cursor: pointer;
  /* margin-top: 3px; */
}

/* .RadioItem:first-child {
  margin-top: unset;
} */

.RadioInput {
  /*font: unset;*/
  width: 15px;
  height: 100%;
  margin-top: 20px;
  color: #AB9E98;
}

input:focus ~ .RadioInput {
  background-color: transparent !important;
}

.Text {
  margin-left: 7px;
  font-family: 'Gotham Pro Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 2px;
}

@media screen and (max-width: 414px) {
  .Root {
    margin: 18px 0 25px 10px;
  }
}
