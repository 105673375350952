.MainBottomBlock {
  height: 100%;
  width: 100%;
  display: flex;
  background: #FFFFFF;
  align-items: center;
  flex-direction: column;
}

.Title {
  font-family: 'Gilroy Semibold';
  font-size: 36px;
  margin: 50px 0;
  text-align: center;
  color: #000000;
}

.BottomMainBlockButton {
  margin-top: 7px;
}