.UpperMainRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  background-color: #262626;
  background-image: url("./resources/dots.png");
}

.MainBlock {
  margin: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}

.ProstoAppIcon {
  width: 35px;
  height: 45px;
  margin-top: 22px;
  background-size: contain;
  background-image: url("./resources/logo.png");
  background-repeat: no-repeat;
}

.Timer {
  display: block;
}

.TimerText {
  padding-top: 21px;
  padding-bottom: 4px;
  color: #BEB1AB;
  text-align: center;
  font-family: 'Gilroy Semibold';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.LeftTextContainer {
  width: 317px;
  margin-top: 190px;
  height: 420px;
  padding-left: 50px;
  background-image: url("./resources/giftBox.png");
  background-repeat: no-repeat;
}

.WavesBackground {
  width: 100%;
  height: 100%;
  top: 200px;
  left: 0;
  background-size: contain;
  background-image: url("./resources/waves.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
}

.BackgroundImage {
  /* width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: contain;
  background-image: url("./resources/dots.png");
  background-repeat: no-repeat;
  position: fixed;
  z-index: 0; */
}

.MainTitle {
  font-family: 'Gilroy Semibold';
  font-size: 24px;
  color: #F0F0F0;
  margin-top: 0;
}

.MainSubtitle {
  margin-top: 20px;
  margin-bottom: 4px;
  /* font-family: 'Gilroy Semibold'; */
  font-family: 'Gotham Pro Regular';
  color: #F0F0F0;
  /* text-align: center; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  /* width: 70%; */
}

.AdditionalText {
  margin-top: 20px;
  margin-bottom: 4px;
  /* font-family: 'Gilroy Semibold'; */
  font-family: 'Gotham Pro Regular';
  color: #F0F0F0;
  /* text-align: center; */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.MainContainer {
  /* padding: 0 50px; */
}

.TextContainer { 
  width: 317px;
  padding-left: 50px;
}

.HowItWorks {
  display: none;
}

.MobileTitle {
  display: none;
}

.PayButton button { 
  background-color: #33B49D!important;
}


.CertificateTitleText {
  font-family: "Gilroy Semibold";
  font-size: 36px;
  text-align: center;
  color: #fff;
}
.TitleTextBottom {
  font-family: "Gilroy Semibold";
  font-size: 36px;
  text-align: center;
  color: #33B49D;
}

@media screen and (max-width: 980px) {
  .ProstoAppIcon {
    width: 250px;
    height: 250px;
    margin-top: 0;
    margin-left: 92px;
    margin-bottom: -150px;
    background-size: contain;
    background-image: url("./resources/logoCircle.png");
    background-repeat: no-repeat;
  }

  .MainBlock {
    margin: 0;
    width: 100%;
    display: flex;
    padding-top: 0;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  /* .Timer {
    display: none;
  } */

  .BackgroundImageContainer {
    /* display: none; */
  }

  .TextContainer {
    padding: 0;
  }

  .MainTitle {
    height: auto;
    /* width: 307px; */
    font-size: 24px;
    /* line-height: 41px; */
    margin: 0 auto;
    padding: 0 20px 0 20px;
    text-align: center;
    /* padding-top: 96px; */
  }

  .MainSubtitle {
    width: 80%;
    margin: 20px auto -20px auto;
    text-align: center;
  }

  .AdditionalText {
    font-size: 16px;
    width: 70%;
    margin: 0 auto;
    padding-top: 15px;
    height: auto;
  }

  .MainContainer {
    padding: 0;
  }

  .TextContainer {
    display: block;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 1000;
  }

  .LeftTextContainer {
    display: none;
  }

  .HowItWorks {
    display: block;
    font-family: 'Gilroy Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 20px 0 30px 0;
    text-align: center;
    color: #fff;
    opacity: 0.55;
    text-decoration: none;
  }

  .MobileTitle {
    display: block;
    padding: 30px 25px 0 25px;
    font-family: "Gilroy Semibold";
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    line-height: 34px;
    align-self: center;
    margin: 0 auto;
    color: #fff;
  }
  
  .CalculatorSubitle {
    color: #33B49D;
    margin: 0;
  }

  .WavesBackground {
    height: 40%;
  }

}
