.UpperMainRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 58px;
}

.MainBlock {
  margin: 0;
  padding-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ProstoAppIcon {
  margin-left: 50%;
  width: 35px;
  height: 46px;
  top: 22px;
  left: -16px;
  background-size: contain;
  background-image: url("./resources/ProstoappIcon.png");
  background-repeat: no-repeat;
  position: absolute;
}

.BackgroundImage {
  margin-left: 50%;
  width: 750px;
  height: 678px;
  top: 0;
  left: -935px;
  z-index: -1;
  background-size: contain;
  background-image: url("./resources/UpperMainBlockBackground.png");
  background-repeat: no-repeat;
  position: absolute;
}

.MainTitle {
  /* height: 102px; */
  font-family: 'Gilroy Semibold';
  font-size: 40px;
  line-height: 51px;
  color: #000000;
  margin-top: 0;
}

.AdditionalText {
  font-family: 'Gotham Pro Regular';
  /* height: 120px; */
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.MainContainer {
  padding: 0 50px;
}

.TextContainer, .LeftTextContainer {
  width: 307px;
  padding-top: 60px;
}

.HowItWorks {
  display: none;
}

@media screen and (max-width: 980px) {
  .UpperMainRoot {
    padding-bottom: 0;
  }

  .MainBlock {
    width: 100%;
    display: flex;
    padding-top: 0;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .BackgroundImageContainer {
    width: 100vw;
    margin-top: 120px;
    margin-right: 124px;
    display: flex;
    justify-content: center;
    z-index: -1;
  }

  .BackgroundImage {
    flex-shrink: 0;
    width: 430px;
    height: 400px;
    left: 38px;
    margin: 0;
    padding: 0 0 15px;
    z-index: -3;
    background-image: url("./resources/UpperMainBlockBackground.png");
    position: relative;
    display: flex;
  }


  .MainTitle {
    height: auto;
    width: auto;
    font-size: 36px;
    margin-bottom: 0;
    padding: 0 20px 0 20px;
    text-align: center;
    /* padding-top: 96px; */
  }

  /* .ProstoAppIcon {
    margin-left: 50%;
    width: 282px;
    height: 285px;
    top: 0;
    left: -19%;
    background-size: contain;
    background-image: url("./resources/IconWithCircles.png");
    background-repeat: no-repeat;
    position: absolute;
  } */

  .AdditionalText {
    width: 60%;
    margin: 0 auto;
    padding-top: 15px;
    height: auto;
  }

  .MainContainer {
    padding: 0;
  }

  .TextContainer {
    display: block;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .LeftTextContainer {
    display: none;
  }

  .HowItWorks {
    display: block;
    font-family: 'Gilroy Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 20px 0 30px 0;
    text-align: center;
    color: #33B49D;
    text-decoration: none;
  }
}
