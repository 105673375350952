@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.FooterBlock {
  width: 100%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px 0;
}

.FooterText {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  /* line-height: 20px; */
  margin: 0;
  color: rgba(0,0,0,0.5);
  padding: 0 10px;
}


.FooterMail {
  color: #33B49D;
}

.FooterButtonContainer {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.FooterLink {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 20px;
  margin-left: 5px;
  color: #33B49D;
  text-decoration: none;
  border: 1px solid  #33B49D;
  border-radius: 4px;
  padding: 2px 10px;
}
.FooterLink:hover {
  opacity: 0.7;
}