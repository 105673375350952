.Root {
  background: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 100vw;
}

.AppIcon {
  width: 35px;
  height: 46px;
  display: block;
  margin: 0 auto;
  padding-top: 55px;
  padding-bottom: 49px;
}

.ActivatedIcon {
  width: 120px;
  height: 114px;
  display: block;
  margin: 0 auto;
}

.TitleText {
  font-family: 'Gilroy Semibold';
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 25px;
  margin: 0 auto;
  display: block;
  color: #000000;
}

.Text {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 22px;
  max-width: 422px;
  text-align: center;
  margin: 0 auto;
}

.Buttons {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Button {
  max-width: 300px;
  margin: 10px 15px;
}

@media screen and (max-width: 700px) {
  .AppIcon {
    padding-top: 40px;
  }

  .TitleText {
    padding-bottom: 15px;
    font-size: 32px;
    line-height: 39px;
  }

  .Buttons {
    margin-top: 30px;
  }

  .Button {
    margin: 10px 0;
  }
}
