@font-face {
  font-family: "Gilroy Medium";
  src: url("../../assets/fonts/Gilroy-Medium.eot");
  src: url("../../assets/fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Gilroy-Medium.woff") format("woff"),
    url("../../assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy Semibold";
  src: url("../../assets/fonts/Gilroy-SemiBold.eot");
  src: url("../../assets/fonts/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Gilroy-SemiBold.woff") format("woff"),
    url("../../assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy Bold";
  src: url("../../assets/fonts/Gilroy-Bold.eot");
  src: url("../../assets/fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Gilroy-Bold.woff") format("woff"),
    url("../../assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro Regular";
  src: url("../../assets/fonts/GothamPro.eot");
  src: url("../../assets/fonts/GothamPro.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/GothamPro.woff") format("woff"), url("../../assets/fonts/GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Bold';
  src: url('../../assets/fonts/GothamPro-Bold.eot');
  src: url('../../assets/fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GothamPro-Bold.woff') format('woff'),
  url('../../assets/fonts/GothamPro-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  }

.CalculatorBlock {
  /* height: 655px; */
  width: 450px;
  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(171, 158, 152, 0.4);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CalculatorTitle {
  padding: 40px 25px;

  font-family: "Gilroy Semibold";
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  line-height: 34px;
  align-self: center;
  margin: 0 auto;

  color: #000000;
}

Input {
  font-family: "Gotham Pro Regular";
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 20px 0 20px;
  width: 100%;
  height: 56px;
}

input:focus {
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
}

.CalculatorAdditionalText {
  font-family: "Gotham Pro Regular";
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding-top: 10px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.7;
}

.CalculatorText {
  font-family: "Gotham Pro Regular";
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.CalculatorTextGray {
  font-family: "Gotham Pro Regular";
  font-size: 16px;
  line-height: 20px;
  color: #dddddd;
}

.CalculatorTextSum {
  font-family: "Gotham Pro Bold";
  font-size: 16px;
  line-height: 20px;
  font-weight: 'bold';
  color: #000000;
}

.CalculatorBottomBlock {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.CalculatorBottomLeftBlock {
  flex: auto;
  padding-left: 70px;
}

.CalculatorBottomRightBlock {
  padding-right: 50px;
  text-align: right;
}

.CalculatorTextFirst {
  font-family: "Gotham Pro Regular";
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 33px 0 36px 0;
}

.CalculatorOffer {
  padding-top: 15px;
  width: 250px;
  font-family: "Gotham Pro Regular";
  height: 48px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  margin: 0 auto;
}

.CalculatorLink {
  color: #33b49d;
  text-decoration: none;
}
.CalculatorLink:hover {
  text-decoration: underline;
}

.CalculatorFooter {
  padding-bottom: 40px;
}

.CalculatorBottomRightBlockText {
  padding-right: 20px;
}

.CalculatorPhoneInput{
  padding: 0 50px 0 50px;
}

.RubleIcon {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 'bold';
  color: #000000;
}

.RubleIconGray {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 'bold';
  color: #dddddd;
}

.CalculatorDiscountBlock{
  flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 0 0 20px 0;
}

.CalculatorDiscountButton {
  width: 110px;
  margin-left: 10px;
}

.CalculatorDiscountInput {
  width: 230px;
  padding: 20px;
}

.CalculatorDiscountButtonClose {
  width: 50px;
  margin-left: 30px;
}

.CalculatorErrorBlock {
  text-align: center;
}

.CalculatorDiscountContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.CalculatorCodeText {
  font-family: "Gotham Pro Regular";
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.CalculatorCodeTextError {
  font-family: "Gotham Pro Regular";
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 0;
}

@media screen and (max-width: 500px) {

  .Email {
    width: 90%;
    height: 56px;
    margin: 0 auto;
    display: block;
  }

  .CalculatorBottomLeftBlock {
    flex: auto;
    padding-left: 30px;
  }

  .CalculatorBottomRightBlock {
    padding-right: 30px;
    text-align: right;
  }

  .CalculatorDiscountButtonClose {
    width: 50px;
  }

  .CalculatorFooter {
    padding-bottom: 40px;
    width: 90%;
    margin: 0 auto;
  }

  .CalculatorDiscountBlock{
    flex-direction: column;
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
    padding: 0;
  }

  .CalculatorBlock {
    height: 100%;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 15px 30px rgba(171, 158, 152, 0.4);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .CalculatorPhoneInput{
    padding: 0 20px 0 20px;
  }

  .CalculatorDiscountButton {
    width: 100%;
    margin: 0;
    padding: 20px 0px 20px 0px;
  }

  .CalculatorFooter {
    padding-bottom: 0;
  }

  .CalculatorErrorBlock {
    width: 315px;
  }

  .CalculatorDiscountInput {
    width: 100%;
  }

  .CalculatorCodeText {
    text-align: center;
    margin: 0;
  }

  .CalculatorDiscountContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .CalculatorCodeTextError {
    margin: 0 0 16px 0;
  }
}
