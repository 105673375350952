.CertificateMainBlock {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.CertificateImage {
  width: auto;
  /* height: 120px; */
  /* margin-left: 50px; */
}

.CertificateText {
  width: 100%;
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 20px;
  padding-top: 7px;
  color: #000000;
  text-align: center;
}
.CertificateContainer {
  width: 250px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* text-align: left; */
}


.CertificateTextFirst {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 22px;
  padding-top: 7px;
  color: #000000;
  width: 218px;
}

@media screen and (max-width: 980px) {
  .CertificateContainer {
    width: 250px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
  .CertificateImage {
    width: auto;
    /* height: 50px; */
    margin-left: 0;
  }
}