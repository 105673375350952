.Root {
  display: flex;
  width: 145px;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  font-family: "Gotham Pro Regular";
  font-weight: 400;
  font-size: 16px;
}

.IconContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.IconContainer.Disabled {
  opacity: 0.4;
  cursor: initial;
}

.LeftIcon {
  margin: 0 15px 0 20px;
}

.RightIcon {
  margin: 0 18px 0 15px;
}

.InputContainer {
  display: flex;
  margin: 0;
}

.Input {
  text-align: center;
  -moz-appearance: textfield;
  border: none;
  padding: 0;
}

.Input:focus {
  border: none;
}

.Input::-webkit-inner-spin-button, .Input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
