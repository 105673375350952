.AppIcon {
  height: 100px;
  display: block;
  margin: 0 auto;
  padding-top: 45px;
  padding-bottom: 37px;
}

.CertificateBlock {
  background: #FFF;
  height: 100%;
}

.ForeverCertificateBlock {
  background: linear-gradient(#36858f, #38a8b3);
  height: 100%;
}

.MessageIcon {
  width: 114px;
  height: 69px;
  display: block;
  margin: 0 auto;
  padding-right: 10px;
}

.TitleText {
  font-family: 'Gilroy Semibold';
  font-size: 36px;
  line-height: 51px;
  text-align: center;
  color: #000000;
  margin-top: 100px;
}

.TitleTextBottom {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px 50px 20px;
  display: block;
  font-family: 'Gilroy Semibold';
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #000000;
}
.TitleTextBottomMobile {
  display: none;
}

.DownloadError {
  font-family: 'Gilroy Semibold';
  font-size: 19px;
  line-height: 20px;
  min-height: 20px;
  text-align: center;
  color: #a10909;
}

.Link {
  text-decoration: none;
}
.Link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 980px) {
  .TitleText {
    font-family: 'Gilroy Semibold';
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    padding: 16px;
    margin-bottom: 0;
  }

  .TitleTextBottom {
    font-size: 20px;
    line-height: 23px;
  }

  .TitleTextBottomMobile {
    display: block;
    font-family: 'Gilroy Semibold';
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    padding: 0 16px;
  }
}