.UpperMainRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  background-color: #F5EFF0;
}

.MainBlock {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ProstoAppIcon {
  width: 55px;
  height: 55px;
  margin-top: 22px;
  background-size: contain;
  background-image: url("./resources/pink-icon.png");
  background-repeat: no-repeat;
}

.TimerText {
  padding-top: 21px;
  padding-bottom: 16px;
  color: #EB7D8F;
  text-align: center;
  font-family: 'Gilroy Semibold';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.CustomButton button {
  background-color: #EB7D8F;
}

.BackgroundImage {
  display: none;
}

.MainTitle {
  font-family: 'Gilroy Semibold';
  font-size: 42px;
  line-height: 1;
  color: #EB7D8F;
  margin-top: 0;
}

.AdditionalText {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 24px;
  color: #EB7D8F;
}

.MainContainer {
  flex: 1;
  padding: 0 50px;
}

.TextContainer {
  flex: 1;
  width: 317px;
  padding-top: 20px;
}

.LeftTextContainer {
  flex: 1;
  min-height: 337px;
  padding-top: 20px;
  background-size: contain;
  background-image: url("./resources/background.png");
  background-repeat: no-repeat;
}

.HowItWorks {
  display: none;
}

.AppIcon {
  height: 100px;
  display: block;
  margin: 0 auto;
  padding-top: 45px;
  /* padding-bottom: 37px; */
}

.CertificatesTitleImage {
  height: 69px;
  display: block;
  margin: 0 auto;
  padding-top: 45px;
  /* padding-bottom: 37px; */
}

.CertificateTitleText {
  font-family: 'Gilroy Semibold';
  font-size: 36px;
  line-height: 51px;
  text-align: center;
  color: #000000;
  margin-top: 40px;
}

@media screen and (max-width: 980px) {
  .AppIcon {
    height: 60px;
    display: block;
    margin: 0 auto;
    padding-top: 25px;
    /* padding-bottom: 37px; */
  }

  .TimerText {
    font-size: 21px;
  }

  .MainBlock {
    margin: 0;
    width: 100%;
    display: flex;
    padding-top: 0;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .MainTitle {
    height: auto;
    width: 320px;
    font-size: 36px;
    line-height: 1;
    margin: 0 auto;
    padding: 0 20px 0 20px;
    text-align: center;
    /* padding-top: 96px; */
  }

  .AdditionalText {
    width: 70%;
    margin: 0 auto;
    padding-top: 15px;
    height: auto;
  }

  .MainContainer {
    padding: 0;
  }

  .TextContainer {
    display: block;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    z-index: 1000;
  }

  .LeftTextContainer {
    display: none;
  }

  .HowItWorks {
    display: block;
    font-family: 'Gilroy Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 20px 0 30px 0;
    text-align: center;
    color: #EB7D8F;
    opacity: 0.55;
    text-decoration: none;
  }

  .CertificatesTitleImage {
    height: 40px;
    padding-top: 10px;
  }

  .CertificateTitleText {
    font-size: 22px;
    line-height: 24px;
    margin-top: 20px;
    padding: 0 20px;
  }
}
