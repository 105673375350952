@font-face {
  font-family: 'Gotham Pro Regular';
  src: url('../../assets/fonts/GothamPro.eot');
  src: url('../../assets/fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GothamPro.woff') format('woff'),
  url('../../assets/fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.Selector {
  position: relative;
  width: 145px;
  height: 56px;
  font-family: 'Gotham Pro Regular';
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 16px;
  justify-content: space-around;
  margin: 15px 0 18px 0;
  align-items: center;
}

.IntegerSelector {
  margin-top: 15px;
}

.InputDate {
  top: 0;
  left: 0;
  width: 145px;
  height: 56px;
  border: none;
  display: flex;
  background: none;
  /*-webkit-appearance: none;*/
  outline: none;
  opacity: 0;
  position: absolute;
}

.SelectContainer {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  text-align: center;
  width: 116px;
  height: 56px;

}

.SelectorButton {
  border: none;
  font-family: 'Gotham Pro Regular';
  background-color: inherit;
  font-size: 16px;
  cursor: pointer;
}

.SelectorImage {
  padding-left: 10px;
  width: 10px;
  height: 6px;
}