.CodeActivationBlock {
  width: 100%;
  height: 100%;
  margin: 0;
  background: #FFF;
}

body {
  background: #F6F3F2;
}

.CodeActivationUpper {
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.CodeActivationTitle {
  width: 100%;
  font-family: 'Gilroy Semibold';
  font-size: 36px;
  line-height: 1;
  text-align: center;
  color: #000000;
}

.ActivationTypeSelector {
  width: 300px;
  display: flex;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2px;
  margin-bottom: 20px;
}

.ActivationTypeSelectorButton {
  font-family: 'Gilroy Medium';
  font-size: 14px;
  width: 150px;
  height: 34px;
  border-radius: 16px;
  background: #fff;
  color: rgba(0, 0, 0, 0.3);
  border: 0;
  cursor: pointer;
}

.ActivationTypeSelectorButtonActive {
  font-family: 'Gilroy Medium';
  font-size: 14px;
  width: 150px;
  height: 34px;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  border: 0;
  cursor: pointer;
}

.CodeActivationInfo {
  width: 300px;
  font-family: 'Gilroy Medium';
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}

.CodeActivationEmailInput {
  input {
    width: 100%;
  }
}

.CodeActivationInputBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10px;
}

.CodeActivationInput {
  width: 300px;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 12px 20px;
}

.CodeActivationError {
  font-family: 'Gilroy Semibold';
  font-size: 19px;
  line-height: 20px;
  min-height: 20px;
  text-align: center;
  padding-bottom: 42px;
  color: #a10909;
}

.CodeActivationBuy {
  width: 100%;
  background: #F6F3F2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProstoAppIconMain {
  width: 35px;
  height: 46px;
  display: block;
  margin: 0 auto;
  padding-top: 29px;
  padding-bottom: 26px;
}

.CodeActivationPresent {
  width: 96px;
  height: 96px;
  padding: 70px 0 40px 0;
}

.CodeActivationBuyButton {
  padding-bottom: 70px;
}

@media screen and (max-width: 414px) {

  .CodeActivationTitle {
    width: 300px;
  }

}
