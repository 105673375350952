@font-face {
  font-family: 'Gilroy Medium';
  src: url('../../assets/fonts/Gilroy-Medium.eot');
  src: url('../../assets/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Gilroy-Medium.woff') format('woff'),
  url('../../assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Semibold';
  src: url('../../assets/fonts/Gilroy-SemiBold.eot');
  src: url('../../assets/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Gilroy-SemiBold.woff') format('woff'),
  url('../../assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Bold';
  src: url('../../assets/fonts/Gilroy-Bold.eot');
  src: url('../../assets/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Gilroy-Bold.woff') format('woff'),
  url('../../assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro Regular';
  src: url('../../assets/fonts/GothamPro.eot');
  src: url('../../assets/fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/GothamPro.woff') format('woff'),
  url('../../assets/fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.CalculatorBlock {
  width: 450px;
  background: #FFFFFF;
  box-shadow: 0px 15px 30px rgba(171, 158, 152, 0.4);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CalculatorTitleContainer {
  height: 102px;
  padding: 40px 0px 25px 0px;
}

.PhoneInput {
  display: block;
  width: 78%;
  height: 56px;
  padding: 0 20px 0 20px;
  margin: 15px auto 0 auto;
}

/* .WorkPhoneInput {
  display: block;
  width: 78%;
  height: 56px;
  padding: 15px 20px 0 20px;
  margin: 0 auto;
} */

.CalculatorTitle {
  font-family: 'Gilroy Semibold';
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  line-height: 34px;
  align-self: center;
  margin: 0 auto;

  color: #000000;
}

.Email {
  margin-top: 15px;
}

Input {
  font-family: 'Gotham Pro Regular';
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 20px 0 20px;
  width: 100%;
  height: 56px;
}

input:focus {
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
}

.PromoCodeInput {
  display: block;
  width: 78%;
  height: 56px;
  padding: 0 20px 0 20px;
  margin: 0 auto;
  border: 1px solid #AB9E98;
  font-family: 'Gilroy Medium';
  font-size: 18px;
  text-align: center;
}

.PromoCodeInput::placeholder {
  color: #AB9E98;
}


.CalculatorAdditionalText {
  font-family: 'Gotham Pro Regular';
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding-top: 10px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.4;
}

.CalculatorText {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.CalculatorText2x {
  height: 40px;
}

.CalculatorBottomBlock {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.CalculatorBottomLeftBlock {
  flex: auto;
  padding-left: 70px;
}

.CalculatorBottomRightBlock {
  padding-right: 50px;
  text-align: right;
}

.CalculatorTextFirst, .CalculatorTextSecond {
  font-family: 'Gotham Pro Regular';
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 33px 0 36px 0;
}

.CalculatorTextSecond {
  margin-top: 57px;
}

.CalculatorOffer {
  padding-top: 15px;
  width: 250px;
  font-family: 'Gotham Pro Regular';
  height: 48px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  margin: 0 auto;
}

.CalculatorLink {
  color: #33B49D;
  text-decoration: none;
}
.CalculatorLink:hover {
  text-decoration: underline;
}

.CalculatorFooter {
  padding-bottom: 40px;
  padding-top: 20px;
}

.CalculatorBottomRightBlockText {
  padding-right: 20px;
}

@media screen and (max-width: 414px) {
  .CalculatorBlock {
    width: 100%;
  }

  .CalculatorBottomLeftBlock {
    flex: auto;
    padding-left: 30px;
  }

  .CalculatorBottomRightBlock {
    padding-right: 30px;
    text-align: right;
  }

  .CalculatorFooter {
    padding-bottom: 40px;
    width: 90%;
    margin: 0 auto;
  }

  .PhoneInput {
    width: 90%;
  }
}
