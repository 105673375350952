.ManagerScreen {
  background: #FFF;
  height: 100%;
}

.AppIcon {
  width: 35px;
  height: 46px;
  display: block;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 140px;
}

.ManagerIcon {
  width: 106px;
  height: 120px;
  display: block;
  margin: 0 auto;
}

.TitleText {
  font-family: 'Gilroy Semibold';
  font-size: 36px;
  padding-top: 40px;
  padding-bottom: 95px;
  display: block;
  margin: 0 auto;
  line-height: 51px;
  text-align: center;
  color: #000000;
}
